export const freeMintList = {
  "0x43ffbf682b61d1a8d7c3235eb724775c5d8479d1": {
    sig: "0xdd48535848b2dff84f6789bf0f94b3bbc5e49e74b9dd9ea31716937fbead6238457a4ed82d7c9bed869b286a1efda62f521d3688d92a714391d6ded687ed242c1c",
    amount: "1",
  },
  "0xfc9dd877930e9799beb663523bd31eefe3c99597": {
    sig: "0x88c58491eec83b0b51f4cd5b800c37750ee4a3960839affdb97fdfaaa9cba5250b9bf73f7b6764cf0dbfbcf78f2fd24882a9b5346c82595d2e8d3da6ffd573ee1c",
    amount: "1",
  },
  "0x8911a2acc24f60424fa8442ee2ce31241eafb524": {
    sig: "0x86db433721744fd641929e4f78c493ad64443e68a22e4458d61d20d009a2b87a41997d7c58f861f6ecd944e114b4c051a75f83958e7449a58eb9995d2b5b62741b",
    amount: "3",
  },
  "0x4830c8c0b1411c21cc9ea1ecf232332496d29d92": {
    sig: "0xc6fe0ac04230f9d6ba6f0f53a4fc55746126bf74c08160367fce9f554ad5a39e506ced4abb835172322ee8d776e822af77ecf52a283e8ed7edd742eb64b7bf3e1c",
    amount: "2",
  },
  "0x1963863a1fa9bcac6695c04d67bf22d214ec058c": {
    sig: "0xbfc4a77b72b19d4814726679ea88bad8d86dd8a22383119b4252ad3f89359cb77220cddded0e9da9874e7ad892a45743f15f9f774704b1f477de14c6569a06421b",
    amount: "4",
  },
  "0x0ae781da98c698c10def6ff9f47abd86bf4f36c6": {
    sig: "0xb74df9c3278c6ebbc5cda7bccb25bc943d7196a7d94cd712fb8e98806ada44516b42b1638179b1c4059185931c3e10a716faf8381cef39208b09da6d8db693ed1b",
    amount: "1",
  },
  "0xe3aa12b55a36843b002b6e54ec43aaf2df253993": {
    sig: "0xa7e2b07104db12ee8a1431dc7368179868568700d63510d38734c861f95cc73136e71c787437e9137af73ae5a948c5fe82d85ad3b31180cde1ed50ef94b9da511b",
    amount: "4",
  },
  "0x59b50e32a967fe6f8df435b11794cbf56da609b2": {
    sig: "0x1664adb0fa6b35e6945041ea248e357c1b99f6d0d079c9f8ce530befe145d21c11be1bf83e50626b61d17f49e9b000b03bd4124d39fc09a23aebce481f6103ad1b",
    amount: "1",
  },
  "0x9b5146a53fa189102c17d711fbc222aae72e0b60": {
    sig: "0x2950d188a7b46d7ecddd196f5c87f544b175b4a08946350b1d1fc696dc42777861371e0985ab29c334fab9ca775111dce5ebcfd3f1cb4c7ad1120f61f0254c7f1c",
    amount: "1",
  },
  "0xef8acdceee68227988cc980a7c9216c4f328c23d": {
    sig: "0xa50b7815e6b65a521441811bf0f2478f374cca528805d0a8432cb25df2c2f01e722887104ec2097680e666f232180b457af7b67dbcb580e1fa3ec669abd296c11c",
    amount: "4",
  },
  "0x4022c74399015ce33e3024912a16f233f21a9ef1": {
    sig: "0xc4ee90c7fa635c30c209f99433f9054650657849f2a683fd528c7c5b974a1b113b2efb4549ae4b7b18f77cee6a9447f7ede81d4b135deb058aec6a56deb363541c",
    amount: "7",
  },
  "0xb8a6b2a4c492eb20686ac1706bc5a2548a9e4000": {
    sig: "0x8deefef6478ee05caea9cbd51ecb9a22001076a2506762e81b0e7502b2cab4f17c62de91d9e2e8080c2819bbdffe6ba82113c66ad7bf53b4b6812150feaab6dc1c",
    amount: "5",
  },
  "0xe60ed2f3072831640e7ecc9f9a8755ea7687bd27": {
    sig: "0x14a61ce113c4c0f6355267e2a09d5d5d64c82a6f14c17a3c9307e816a4c50e060e76ebc59cba8a6c04abdeb006e707fdf4fe8a6247aa67fed322dca2d96e83dc1c",
    amount: "6",
  },
  "0x9d4c907020dedd4ccf0578f459de906a57c0df2f": {
    sig: "0x6943b5404fc9274f65d3400b50e4a6ae19a285c5a1d2aea4e4be517057beb1812894f08ca71c4d02f582403d50e7b3a2462f8f6f386bff63d3f53f24a7b6202e1b",
    amount: "1",
  },
  "0x83f41b75aca71d2bc9d3bec5e2f028653c57588e": {
    sig: "0x8339f495a2901c9af8e72ca998fc579248d46fc38e28bc73df22c6ae681ae72a00fcec34b38c6e19d07f78ade34583497bc35818b02966d4880e0479bea828621b",
    amount: "2",
  },
  "0xed3f5fa107df019d243e1c7bb02adb2eb2f7af6c": {
    sig: "0xd2a819e0b21ab1de709dd5f3fa48a47860875ba58f9dd2a2d14245af161eea6f64d9b8c3b8dc78a2d66617bfe93b37508cd1b1608b10ce4b1741a1dda67cf7501b",
    amount: "2",
  },
  "0x7f09277b9a96d68fbf7b1ae552e4f1c5c26f2cb8": {
    sig: "0x07feb01a06f6d8599493e9f4b9b3da758d937afaac4a6ea0ac0e5809ce1c965d0b1dd19864e6f3c7de80815720bb682305dc68a77565f079f10da51535fd8e211c",
    amount: "3",
  },
  "0x60d620c18aaa9b12dbbfc6ff5597b9251b3f58c3": {
    sig: "0x80ad53f963c27e79835fd16b52d690e810ce9f8a2caab977a633d474bb2a596e6307f985f1eb80087e2bd33210264edae549f382f836445abbb38b2c7a764d791b",
    amount: "3",
  },
  "0xf52d6c8313d57a6db11ca26020b0e70aaf6c6faa": {
    sig: "0x1f384b9e2f54147a5abb1166269666ed6ba3aad88af9b6ac01b01141724dde357336dc3bb9f17ff1af504f07e83a66435dc1eb87f5301bda02b8c09a8dc1392c1b",
    amount: "2",
  },
  "0x87b895f37a93e76cf1c27ed68b38d77fee0f7867": {
    sig: "0xe6f16f4dff5b4e2262c4f2acdd82281012ca6bef62f9bfac074f48bffee8d0a410449d166440f86aa6d4aa9e98091fe2d6a780321b915f5509d55ee03318610f1b",
    amount: "1",
  },
  "0xc28036bb255840873cc530a4845617ec7939a042": {
    sig: "0x484313eb77f75bbfa86260e7e43b4527551a58d843919b2d1698ddc8821a1f99350a99fc7fa1340eb6341d2c2703b9e7b3b6d9eef58d3fa7f72f097bb84e75081b",
    amount: "1",
  },
  "0x484ec62385e780f2460feac34864a77ba5a18134": {
    sig: "0x4d7ee92c2bbbd8d0fef72a86d759cce8cef71335b9cca215170af81b0e86d92b37ac61c4fd0f8213f7d517cfa797c2a25571913eb34a18ab97c963bd9e91dbc81c",
    amount: "1",
  },
  "0xc0619bb479af57e9e76c1fb24bde919364291dca": {
    sig: "0xa75044f58ecd5f4a5b6b4e33af7837a9c10711f95357b2cb99dd130900a2aa27739693c734624462dc55331a0bc52c6bf8a99f98e2bab765141605e0df6af15b1b",
    amount: "2",
  },
  "0x1f381bed145dd325dae05d88dd7de73e8c3b5740": {
    sig: "0xc750b8a73a9b84bc87e8fe939bd08961f66cfcc6855732bccc9ef0021d851cd21581e727a5c1c7da8a9bbbf270b82d2b6252a79b9ab0b727b58d47e5726d62fb1c",
    amount: "2",
  },
  "0xbb967d46dc3e5d0e300644dd15e3e1678100d70b": {
    sig: "0x65dd4e379d3f4941a19c74b1d41cabe2fab58e918ca2e4eb16e508e18b6bb61220f8f42bd74df601add73367d5c415a21a4fab182a97e4d638e96554444e4a0b1c",
    amount: "2",
  },
  "0x2c1147614c0dca0b7ca429641a7049dad5ac5a2f": {
    sig: "0x82957f0fa9de6d382fb077f2ba90531044644a3a0fcd7059cf9006fd25d1aea0393d0ea1ef5923cda69c8c0e135ed06cea1261f8d7848d4b2bbf17f5a81aba4a1c",
    amount: "2",
  },
  "0xf961d2206c2de019bd28065090d20bfb988163e2": {
    sig: "0xead41078a512bc54196a3e65e63adc66a2b57b1fed5441de46343eb21b2588f04b1e96f5739ff1d1747465cbac4f61661731726f35eb556fa426069422a6cd251c",
    amount: "5",
  },
  "0x435b1176860f5f4f96f64ad8f393b4c69cb907a6": {
    sig: "0x69481c2ec42cd3c8b9b26a8730db063140d358cf47060290677e2321873688737570e787765c2b61b392f9f5df3646d43ae37b7ce0d7c4b6aa600b7e2d2056eb1b",
    amount: "3",
  },
  "0x963c64a0bf0cc791f4bc31a30dbf53ebab7b6ec0": {
    sig: "0x173fde124c2a00aff1ea9f52552cd9a8d7b8d5f846601adc39edbc41ff7bc4f8413d7ce6b42fc5a425fa1c0266ff2e8660ebd2a1195afff2441398343fa4605c1b",
    amount: "3",
  },
  "0xf53a550800de2523c8ae1845db20d385a4a83c56": {
    sig: "0x11eeb7d4333aeedb80f0bc0feb9384f1d11d5e99975bd34e25060b29a2ddd5201a2608254f6db186e90ca49573fdd7ad1dd87b3beae9cbf9b675f03d33bddaac1b",
    amount: "2",
  },
  "0xe5b9c0cc6ce533a69c32ddd4e2f03a54c776fdfa": {
    sig: "0x8473d6e10c4bd514fc9d26a77604b633c926e860b5bca8ced49808756c5d1fe05a33b4b606f7d226fe60785e83d6b948c2160393cea6afbf8e6a168435d42c3e1b",
    amount: "1",
  },
  "0xb7182b36fa9057de8fd37ba9b01bafcb2a75e31f": {
    sig: "0xf73c1792836b6141eb7140fd98a0799a7772194d6c28120353126613703359fb545149b54d6c4091434a7659cabe5310caac661ae8de578b330265829fa407a91b",
    amount: "3",
  },
  "0x15528847097eaf5af6c34f13ba655821939a6204": {
    sig: "0x2d8fb49152600968bef5cda3f6e06f0920f5970f35b31d6d8aef65bd4677a3685a7a9c937acd57d859ea2434e6da9eb59b06866b6dc0da776ed6274603de2ff11c",
    amount: "7",
  },
  "0x72901a3ede195490c022beab3adddb8b2d30123f": {
    sig: "0x68c623904473de779d711d5fd7e75deacea2bdc0f00d53a14dc30d0847c26b241f1704b6e01d807346c9a847dffd21fdcace7bd78e1bf1875f4649ae21dba0ff1b",
    amount: "1",
  },
  "0x312752f8b3c686f29e0ae73459cc1c7d547ab9b6": {
    sig: "0x01aa8ac131715d73e77427342c2cc2ec03c37622deb05fc610cf6959fea4346361023f3e4a299fa1a083ec3e1d9798b703b69d515e23bcf6701b9b7b0e9fd1091c",
    amount: "1",
  },
  "0xb4df5fe59febf485ab55afebb1ad4fade73e95dc": {
    sig: "0xbbaa5eec17d72f12800a197b8179415f7dbaadb2d4b3c713b85550f27dbcbe62726a7528b03415ad1fa2b6b95ae2eb1fce60374e31cf517ecea8aa70fdec54971c",
    amount: "1",
  },
  "0xfeca9baa9f27b7dbe0964acffdeb09b8974bab79": {
    sig: "0xdd98e2b535b77b0642b2af9b2ef96748019bd1902cb5cb98b4facb1cf0433434605e97d7471e1210643dcf9a8f04a3f806a6a84664e9435fdd484ac93fc6801b1c",
    amount: "1",
  },
  "0x8a3ab13e594c0f81d16097cc9e46865d17bcaff8": {
    sig: "0x866082c0eb3fa99c9cb04b37c4c415abccaa6162ad37b836648f506b8f5fb21f7c8245b9286909038733859730a2293035fc437466d858c3d19531a9fafffd3d1c",
    amount: "1",
  },
  "0xea51d31f232a464e9b7500a1cb17f5f5c068e62b": {
    sig: "0xc913e0b1cf40ee211ba0216916dfdffe19ff13e7b58eee49ae41efdfbd5e1a18557947102a179a887884a1654b1235613c4a2a86a1a937665b7ce8346e3174f41b",
    amount: "4",
  },
  "0xd2f354f74b88401fe827eccec38be9cd28ed62e0": {
    sig: "0xae5ad67b2ff96bb7f4549cd116319a0f58e68c1b9b481ec4b04bd63cd442d8581e1889ec2045a9167eae137519f64e847c7b604a5185fb072b63d43467b8428b1c",
    amount: "1",
  },
  "0x2030246b1d2bb48b4def52e3d60040fcd2ed2ba3": {
    sig: "0x0cb8509849b97463d6f81abf46c88222209f90510044762dd968d7bf1f7b3c5b0de4d9f02676ec85edbd9d67ceaa6c299e224b4f28692682f98a137b2f7c460d1b",
    amount: "1",
  },
  "0x1be9021726e5eac98bd3079b49a8de6e60744e04": {
    sig: "0x47a0d966596536fc5c3a0596b6aca64a69017b6361089f1f0478e98dfcc9a7945dd62739afca633700ba4a70973e72c4de7dab4be336ec342da8f6baa20e15991c",
    amount: "1",
  },
  "0xc07bc41edfc601ad4e88512217a3dd76ff181191": {
    sig: "0x07ad360c50045118a520a26470efbf7057ee3bfc250cce426dfe4f9305c44527096c699c81ff7c5bd30c0ea46da26ab84f1fa0ceb7f96da6863ca2a3403c8d2f1c",
    amount: "2",
  },
  "0x6302747a76511314ba9fa486f5e2125caba1c77f": {
    sig: "0xe6103172829556653cd53188aaeaaccfb17685250948366a451549f286403d73542bc576e5bc6778e03c217c9897f5526410915b26968fd75eea3c3be311efbd1b",
    amount: "1",
  },
  "0x86727c57351b2e00e4a36c71553a87a917e52bf6": {
    sig: "0xe90547cc1161daed6d0404555640fc2bd2838aed267491b9c164349cdaaae6cc6a44fb4e637e23fd485d4fd241a780f6d06ed83a3f00a7599deb1f3ce6ad2a1c1c",
    amount: "1",
  },
  "0xee17c26518fc461d5788a564e529213ecc159911": {
    sig: "0x70b03093e36144264ff8af2b919c24c8239e48bceb3d2f44ec6eceeb05a9eade6a8088d05e48dfcb737f6bbe10b391d4cef3a38ec0878ea061ca5310fc5fbe721b",
    amount: "1",
  },
  "0xb9c6755f45b89f6b06de613de98565d686fa6234": {
    sig: "0x26eefc7e726c56c540e42862c947d29af3ee731d3d760818a091b39fd5724af3318b59f3ec43c237b0c8461198833c7c99308111e305d90c327d0bf9e6a8b36c1c",
    amount: "1",
  },
  "0x9f7df7d3eac4dd14d42ef761c1974ba30c09fcd4": {
    sig: "0x715c8444866fa4a163ce96ef0656a1b4db9392d1ec95a8c3d4d54cc62097921b2485494641974c4f343067b4d8a0316128a89582ecd2ad7272ca8ee678a30f7b1b",
    amount: "1",
  },
};
