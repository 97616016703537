export const presaleList = {
  "0x2493c86b62e8ff26208399144817ef2898c59460":
    "0x668ee2639e3dcfc39d5edd56736733649109ec7f9f6f6d7a69df6ae1b1e32061656d6298ca0e83d70d535bcad58be1877ca476e2ed0ea4c45785e203b6da2bf91b",
  "0x7ce79178661ed38089fcdb4e28ceb2cc18508d82":
    "0x5848bf5f28658eb936ef45dbc00ab4cbe998bfdb01156a19f5b2c0f6ae86f7a657321cbb9c37c3e78672d7080b348186945d9b29b87b08b847a993f5fb05f53f1b",
  "0x3bbbcbc1c0b584f61ed746096e7b67c6bc2dac93":
    "0xcb3b75d66998454cafb62033fbf959369615a86895757bb6c273efcee5cbd6923821f63ce69e431481ad0ab0d4e91592458364c5ca1e521054136621d34f36871c",
  "0x1722ee7190ddbbe6a17f9dd7239ad815382883f7":
    "0x70c57d28534ab183426122b5b047308b3d79d603a3f192e02b20abc4f45a566333bbdeea98d80288203c527ad19beaba6d2011e3bfb2b0a5e8e9f577fe20fce51b",
  "0x0ae781da98c698c10def6ff9f47abd86bf4f36c6":
    "0x41bdd4f836c3010d4bbff145d24b1788f0557680eff6022b6f21db593edc712c0a688e400201213c87061e467b318dda385662f48e93f6267994ab48c3d301af1c",
  "0xc28036bb255840873cc530a4845617ec7939a042":
    "0xe18afe5338609dcecc890540ee3fe0f522506692889221fea9ee290f0c3fd800322122ed41b60be71356726001b9c46e67e0382e3c95a3024ae508e480271e551c",
  "0x1656c5169a664f83b290b0349aa658d435c73f3b":
    "0xd53ba79357f25b850841e64969111f9e5e4182df600ce009c94cd4b6a4f0989d27250e83472ae0d8c858e6c227b5c355c8104c29600b2ea10427ecced6c9ef351b",
  "0xb99db2cf66e66d194fd016e3f439c56ef069ef2f":
    "0xab5d3c21bc3672d8045e99916092529bebfe50fcf2f96e2d784a66cafdddbc012f4cbbb7930f8d2a6c6650047e71036547dcd9709ac79d82ca407e9ce48719cc1b",
  "0xaaf50ea472d57af137f8d2670e898dcb90c36bfd":
    "0xaf7de1f32f213dd3f51f5441269f5a3f37f2b08b9f2c2456049446e05b830eff6495c44b96a0bbe5b16d68a041a3d645f613beb1fd305cdf7adcf9c1619e3cd31b",
  "0x262ca38628e153ed3ea40ded4087cff0daf74d3e":
    "0x0622dc50192635de28249149279594faa9c9065d818bef5fec36f6e5879688ae49eb542240e43dea7ca03ec7732f3aa2546ac5a258ee9ece68f74733fa1e8c211c",
  "0x10c342c1188ce7c8b3444d8c7b99765dadd5026e":
    "0xf9158f5fe172910881a4e61e137f1457095be184d19692cd277845650d54495a2645661ac5bd491679e9fd28f728db356fd465e922623580f1edd66383996c701c",
  "0xe6b211af17b678b125ec5547356358831512e04a":
    "0xec0e91a80ceb0c5acfe40f437ebacfaebc0fc476098a945f26ee302a208529455a2ad9476d89d894130710bcf9b5b0e2cb3942b157b98ed413bef55af80e33841b",
  "0xb0d89bc8efed2a48470b6a1895e87ac89afad07a":
    "0xeff4c37d410d4e72152abae12dfe058664dd56e97f98b1224b957a350701bd736801f10cdbc978375cc0b4372bc22c6dacf4603b72a35da85cc0e5a7f1e651b51c",
  "0x8c8f1be5dbdfa432bbfb33d6a13779e889d8ccf9":
    "0xdd5afea37839469fc41485478a1d24751bb72355a18d0c6cefe586b898ee6c741a568220bc40d4da5dadd09b4874e0e07a38df7a18f70316c5409a359ad2b0e21b",
  "0x5d2cec442924fe184363aff7cd8059c6943ba4a3":
    "0x04f0cf10c48a14299138c948c75a291a11f58fac23eefd2a9568911976011e1879ea059c10713581943522b73f88ece9ba4f6d585c819b34993edbbaa50334ba1c",
  "0x3dc0f9fb0cecade0781798f0c2f7a60942b1db0e":
    "0x79b001f0f53eeac41455a952d520908378cf4c3e065fb34a1980258ba0aca89b05399f7329faa5886391daeec14826f8d1c9be6743305cff3622df57d750ef651c",
  "0x87b895f37a93e76cf1c27ed68b38d77fee0f7867":
    "0xa9c00bf20c5872e41bab551a3a08e9890c77409ad537f88f7a3fe081508d790b373b40666998320f764aefce285f6928d0eac161f0fae8044500ffdb1f7d0d291b",
  "0xfef4556b2b9651cc2d5d30d1a4d81f3cb534c34c":
    "0xc2db3be692211b9f9a97a4d1510577533dab766a2bbe17dec29f593eafcd07a95c44632f81eeaf7623cd5af3c0036259bf43bd0e735720e6b1f532e183b6b9d41c",
  "0x82bd581810aca98966e69dda7c27994a8ba2922d":
    "0xa21866cd10264095c6f320b52e02403b618105aa6feb4dbb7bf3a0ec992785c42ab418cf168be00f2628523d4e151a3b2d5b182a829e8e431174b5854484ff4f1b",
  "0x9b5146a53fa189102c17d711fbc222aae72e0b60":
    "0x39ce7d371c60cc45e15a1774291962c900bd361d204fd036fd97cbcf5db1ba3e227c83b66d1e8b3131c3d43a801bd99620bf37693a63b05049a6025c1a3c1b321b",
  "0xc7b33a27a236beb7c8de99f719595c5774e1db57":
    "0x297d07f1a66a52048635c07e39105c3d300b3a595c61422eee4c9823c9493c1a2f9abf41eef4044dd6c3c7ce3da6319bd766635c6befb43f40a6acaf47f707c01c",
  "0xf2e7cf94c6bb9a1439b2441be4f062aa91d91cbe":
    "0x779d264cc0213bb3627e8181d597edfdb5ac21ab49f630f8e6943efa8e51e2ed1803f371ad0db7834c28d8900a208781e4904952e962139145b61b3b630c58ea1b",
  "0xa493f473db364902868e3634e964dc98739bd33e":
    "0xc48a515415c6d200583ad1f9fb23a2a37d93428ac9c4cbc6c56ccef925669a6d5cf8eb4e054a2406ec8d5a62b7e8f91c6bed6a7612546ee7172117e60a19b5501b",
  "0xee17c26518fc461d5788a564e529213ecc159911":
    "0xd665d75f992feadd7422d84fd40fc9acf26e33cd5aaf587982f53e44de0c77d764358b6ab966cf660dfc4c432cddc30928b27bd09b27829c23de0735911693f91c",
  "0x9250f8149067fc068189f95e92f0eea9673f62d0":
    "0x131c5d9abeddbb510a4bfabacbf6c1d292c1416129257c05a6f9cd2f2ca3fa86604dbecea5919569cff99e71a09185572a22ec6243fa03d459480624cb8bb4841b",
  "0xd2f354f74b88401fe827eccec38be9cd28ed62e0":
    "0x3d903d696fce4bb738f5e139e4a9ea830449abcf9e67b1253cf0bb6d2f6174a467fd7a1b2d6b8574ecea0b952983c81e248a400963688e3d9febc1a6eb98b66b1c",
  "0x6302747a76511314ba9fa486f5e2125caba1c77f":
    "0x569931b1cf7fc189ce6a0735178a434a0162cff87b4003062461cc20a129d6d661722b449ce94837f8f59c5d40c2dcc22c2c419447060bb4cfc08de87587e2941c",
  "0x312752f8b3c686f29e0ae73459cc1c7d547ab9b6":
    "0x027ab7dfc4bf9c327d12cb3cdf48aebe8b46c5be9c12fe2e2fa836ddab90fdc10af15a28d00fba3cec492215432da3ff0f740cbba17788e1f320e178c0a16aa11c",
  "0x414ce028041f44ed40a06e6a6d8985fe2bfec042":
    "0x2732e3e6d70a2df904daa1d3df827949db6a9a7d6abd606d5b810151f54203cf3b925ccf2704c39179d727d92c2385ba73ca726e89abf8ba945405c0091653da1b",
  "0x2674d7c7d6ccc2df9fdc81f21c2e3740e09412ef":
    "0x8a8703113380f55e8051aed95b5bd472acb56c6febe8a5c02530d99d282e5bcd6cbd4a65f7ab70c37315504c5be051ad53b1d91e2f679f08edd1e51fe3e207671b",
  "0x643f4c62a319a83fd08755d0263cdf67f1c4cc0e":
    "0x36d0a38b3ca442d554adc96325da2ec7c3a691687eff08e55e1657fb1a5189ee510c6c37f1e5463341e4c87351a633f885a60c5b3bda710cd2d79e1eed4efe211b",
  "0x34067ef65d928505b8ab54702c1a585051d27755":
    "0x70cb9fde93f32205d4c6ef4c39e4be1ab2ce2e0b9c12ac64b823fa75673330fa61c885bb67e59252902b0ea0c2d685e492fd22add343fd47089b14260d7c37421b",
  "0x38d45e8e519cb3c72f9f890dce4a5dbb0608f8a3":
    "0x51cfa26c2abd6d64382d7b17e51016e562596f899436a673165c89cc4612e25b78ca72b608cdec31ff3a13d25c3125d8a66c6b527f97de7512fc9f00abbb4a301b",
  "0xf054e38015de6baaa244fbeac8daf6bd32aa96b4":
    "0xf0e3e6fa16da797679ea9a64eb6eb60f86d61efeda28d5b0c1fcba9639fe701b179bf1b1a57407e7196b016f10fc3b40fab2261143dc04b9177452d72e3ec5391c",
  "0x32c2a2f9b0cba81f80502cbb287177cb5280660d":
    "0xd54e30389a39891f245b59a158da7a7dca81b3498dc9dbc554bfcf8b841d75c965901c30345bb561b78a5c02ddf8330a11ce9e64ec017df34a73c1aec9cf06981c",
  "0x898fa342aa2836d6580c6b13287edbf25564710b":
    "0x4f93567e952065d43e3851c0f91b8c46eec43abc5570ec7b0c903f046da954200b7644ba7435732eb4a5d2f47250f526360c7a9c80486a700848cc8b62d2112d1c",
  "0x2523821b931917022d083cd352baa57828f906ba":
    "0x271028ec779ec51766f5cf55394384f76140c8afbd8bf357180d0e6a4a599f9960de194204ef670c0cec36742a298d37ea86cdf39a58bbc17700e30597f545261c",
  "0x5787f7f768d741445b686457db448ea62cc54b01":
    "0x73e5869cf9b191ac8b34e040f000bb38dacfca6df5a0332029bec26416af7dbb02f9024b34b0c51e5369a961d71e163a6003abe39f4a19f3c706a33340389be11c",
  "0x49f875c4e6c32ea22a1e715dffac28c0ff402faf":
    "0xdfbcf5e4a97a574ac215b18a88c678ef42de3d11b97f22b9df50cd58faf2333625e1ec0087313617256670bdeb6012ef607b3c87c83eb2b87295567e1fb7fdce1c",
  "0xc13fa92a19ead57924ba8657f090bf596ceefae6":
    "0xdf677843b1f6d061b29cc43c7228cd303d7dfdd1d8079f709fa33c6aace6586a187c3179576fb368c589514237c99336087c35b12b34716fae2c58dddd61b1f91c",
  "0xac92beb75eab3bf3b165a58deb9f67a2164a10e6":
    "0x32348507a9786ab1970299d3fb532acaa3ca3f954518021f985a6fe16e91e2d15a404642ab79c618703b9d8f514638395b1ec1fe3ba2bcd9a1213e54f1d2d7a81c",
  "0x066f2d5ead7951f0d0038c19affd500b9f02c0e5":
    "0x2541154788d48a61fc6ed8c253788da25733d157664f662b49aaca0d8b1ea31e38f93a82a4a9576277e8ffa44d4c917ccc3a3216681bd313c210f425db5d3ad61c",
  "0x8a3ab13e594c0f81d16097cc9e46865d17bcaff8":
    "0xe5ffaf725660d712deeaa59acd7267ce12c1847e69f72cee2d4b5fe860488a7f5285aa278955410b3cb9d8f1d29975c6e387aebc51533aee09b27bec149a3cba1c",
  "0x031602f3e19e8c532e2b83000166e5d4255324fd":
    "0x74a17c9278f22fc76ffc3b01c77b7bf7a0fb2e913ac4854bae630e4707ab78005823e23192846fd5cb7d03ffcdd29c5dca7b063066d3bd82b3071a54858dd97d1c",
  "0x86727c57351b2e00e4a36c71553a87a917e52bf6":
    "0xd737a84cbb8628606fb1a40342bf8cd9e92d0e5932570c23c4c1715a7aeccd711351a8a2efeef0c89db4d76f84bd8074d6302696750f75c61c011a92d406e5061c",
  "0x1509e1244aee817ec10fcd6f51182a562ae90b82":
    "0xa4b6b923745d6ac29ca478943bc54979f7800d8f957ee05d887173c2b2b85f370b18f0e20abfda08e8a4d78fe035e5db1e5f9582ff40010b4459f61de7d30d7d1c",
  "0x50839ae2c8da2ab58fdf81ce12f4e4233ddb8494":
    "0xe2fd0f527934f48f5d09d52b659b5d0b2013b3b300021e9d355b53cf1dc70ccb320577ec375cc025948674812e0bd3b3d569d5acc9ba6e36d5ed4a30d52922441b",
  "0x963c64a0bf0cc791f4bc31a30dbf53ebab7b6ec0":
    "0x2e9b38f8eda871e3f515e62d37e170700e42a4557d9d3894165edbef6c432ad61aa5e21b391737f88c8200c17b550356dc648cab760133d6d7a5664bfd0ec5b41c",
  "0xb692f635c2826f5d69d4ff277ad3c4b69ee2e8b6":
    "0x24a9dc3b73d77403b6ca58eede74c7daf9234047f22dade6441204a37de8acea3834d6d0889bc8dabe17f84cdb2ea692d7c998b7423dcb0be22ab80469a147db1c",
  "0xab428debe160908131f10b3d7ffd06a015518489":
    "0x54c0cbab3cf0dde9aac18755df04d8396f194df67bedc8db57a8c20aeed1d24d0874cc8658c29651bb76bcab3b35b60adfbe65ffeb0c83fab411c3ac1c017b651c",
  "0x934f4fce8383b17156384e00b31ebc6967abd531":
    "0x8fefee71a25bd1e1bee40b4560965c842bf65b0c209d77bcec3269fd7b918833557d1de5ccea990ad9637a215f53c4292af50f8b52d5ee702d922e3bee8ec4601b",
  "0x167beb5bb21bede7461ef8716f765371e45588db":
    "0x6ca131d87431d9540bb10649f19ddd5bdb170338ce3f2bdd012750a3a5661c09679f8c3c672b63fb8415cf4bd2e96b85fcf1c4ba0f672749d4ffd9a36b8e76491c",
  "0x134eec7def018900308ff83537eaff1dfc8de36a":
    "0x46305f44859fc389d805962d50b4ca8747ac54cbf0d6d1ce5d815370451d84cb4c9ade07aa9d695fd103362d6cd0a45710edc96cdd4beb0964a9500f5ab275021b",
  "0x529d68dd4dca6052adb78e12a5d4febbe8b42745":
    "0x63b5b411d3254fba762919548836fa8b7f1ee1c0c41dd4bdca1674e1d525da3324893ff097c35b54cd21d62abb21f6ed72661237196064d7115b8c4665f2694d1c",
  "0xcafe5296068cc23fbd41730da7644b237a9b0983":
    "0xc0cdbf4dedd6fabdcb8f34eddaed155e5199326d4ae51dbfc02c889fd7f7df1c312db0785f169bd36424795d93d512b4386ce571d44cb258aa88a7830eb7eb2e1c",
  "0x672d5b9fb52fbca7c31c476ee22df302f5e9dc87":
    "0xe38cd0040c30be587dcb213a5badec7bda900d20ed8e1f178b60f680a19203136d0bb237cf326e6fcd86a0160bd350845b9b2d0797056f9244ebfa5defe454d81c",
  "0x477ab2a1250ab626e83f786b4c3835eb6542428f":
    "0x1b81cdf0f25d4d89cb3de6eefa8a001667c3b23eb96105fb3892be271676d53d20a7c21ad6a39df679e71a5e9b622997adcabd199a03a9711cbc2023a4235c541c",
  "0x6a66a98eb4f7275907dd482160335fcbdc5a5c7d":
    "0x61cd8b9d2283e30d83764254cedd72ef881577b425a5ec0de3f47e40535bacc26beecee3dd2a4f0a33c19215f514a883ee5fb36aea44dcfb733440e4f76936161c",
  "0xbba0cdb7c7960497882ad4b65aa44e22483166a4":
    "0x59e1514071afad0ca41991b227b0c383323071ac4fa6f5992d1f54496047f0cd34325bcbc3596c6d152f4607d5799cd5fff1940ad773ce9214f080cc037dc6361c",
  "0xa96d17896750e05e6f7ffeff1fd7b642911c5812":
    "0xc1f3331d1cfda8b80d7cc81f91dc199c6cc5aebb4a2f81f91d38db1a38ea69a15daca0308b7c5e932a814deb3976878a57afb4d47344926a556afadf7700ea161b",
  "0x12c4084c1f508ed0b4ebd6c143968273c25e789d":
    "0x4c03c5007eddc2a45e5dcf058a7ce7259570a1ad260cd4154e2323785ae87aab3cf36dca306cff45876454a9f62ddf38558cf4b5e335ca52192b016fdd87fc0e1b",
  "0x5baede5745e76d53e61ad72c9fd4627ca0105764":
    "0x896cc89b79d34e492c9cd572c925a23ed4d725e052767cd3061a8de69a6af6f44d1b1ec575b9481834faa4aec03852dd0f77c5a6c54924168d0120afb52670cc1c",
  "0x405020c797a64f155c9966c88e5c677b2dbca5ab":
    "0x4791f4828c29d790921811e418a4d47751324545d07cc03af26fb1f3bfc2310c3a095ca1474d920ab10918ce1a921e1f9c71d072e35b96e74cecdbd5c31bfa1c1c",
  "0x934fb1b226796736a9e30db499477ad9bf52e3ce":
    "0xea42d46e09c6712b9e500dedef3de7df60f9d2a005849a82db8edda6ce72f6c138aaf2ccb7543e74f6079ec3c885321eaa549ff85c3092f5c108b6a19c0643ea1b",
  "0xfc6bf67a92b09d6230b8a7333c7e2f729153dd70":
    "0x33679612f9eaf9c1be98e7e2bd91f98f87a434342e538e4f570c5a131d4df0622719308b9082b9c4fc482ec305d6e3bafd5773ee99e6b5d8d4106a392e24e4a61b",
  "0x6f409c1d421308589b026c19ebe178535139fdf6":
    "0xc9f0c68a7695b303c1d660dea656a2980d23ea4ca3f1bf6fc31ce7164f04a5b21effde79efb99132ac380b0cf0b3d6c070d309ba94af9e26bf58693a3e8a95101c",
  "0xa3f070baef828f712f38c360221b5250284891d7":
    "0xc3ad38a4874d24032eb51bb6dec6d241f4d3184bc7b0f40584255459fa51f83c6420e5a9c9e4b9683a8126ed108b5df9898675406de0f491c7223ae8ba5dee4c1b",
  "0x4af9597cc60294382f8300f2b76140179cdbffc7":
    "0x466052aae184325f9f21d606b219f6104fe899223773b596c4f2c9408d2aefd74152c25dfb1cd71c66c1debc2ea962cbe1d2c8be0c7a398355620b5432bb6aa21c",
  "0x6f3c953df2191831ca9f0fc6ef3cfedf36a9c48c":
    "0xebcb3209ec28e3ddf9521c609c1b007f1fea70f5aac7412eccf2677c22f0930b0a7645f6fde8905418f593d744cf3ed76b0eba321ac33b37201161dabe2e2d471b",
  "0x72901a3ede195490c022beab3adddb8b2d30123f":
    "0x18643673335eb344dfc697434a13a7fc77766775b2322828918ff4df93f437ac67b693e9d7debc37666f099ffbda8047f499e163d80a51f2936bc340063dfa591b",
  "0x6e64a4629575ace2d27813f355a915334345ab00":
    "0x6381aa4c3ff207ca68931a14f636e03d8b32a463329792d037d5dcd8ebbddcda5cfe04545fa37262f334d7897dac5acb3c46c0fc15dc29574f58a701f9102e4b1b",
  "0x0409cf2db6d8038ad33a2e59ffea9f58ba313072":
    "0x1f3e6123c741af5c2a8fd5c230c50f33dca943c6460b4c1a82431c8504a8fc9d281ec9c00a23b725d1dbec5bc12686956da97e38206718532f2cd074e1b12b2c1b",
  "0x61532857b7a5f5aa4a49e51e6c938bb1b20a5f5f":
    "0x8be79a136412f0c2f3bdb1000badeb0c00b5a43392a0d9703dbd1bd978c8c72549d713e969aeb7b59ec5c960ecae0d7f948b95721fb1dabdee1d997bbee64d571b",
  "0x052b242265d8ac9a7ab8e5a70c70b78bf5a02444":
    "0xf72cd97e848ca39439c1af661bdc1ef95ee18503ba6bf2dccc6489fed04670ab2cf5a115a6528b3140957540c09fac3fa991e009648ff9c9692a0124ec17ee431b",
  "0x519331f1576f70bf2bd78471968b66e743a3d357":
    "0x8845b8b318afd5b54194e173d43320d2b08091e3b1a1809450daf0898a6a28fc2fdf9114d4f0f6b595a0c8368ff19c818610fcda0828ee0800f6ede9eb44d7831b",
  "0xfae8f86710e97dc8b667ddb55df5d62c3307e5f3":
    "0x6895897c8f6ec1300d11f6c2a2b81207e736f337a7729d2bb01bb5644f435b7a152e55774d5ec96d857705cde989f168805ec8603057a8d92a41c8550fd9f4371b",
  "0x6425e6d1d9d2a36925b76419e91c32a3b68e0b89":
    "0x7692f5d664211998dac74c48392c9b836bbc53c3fd7d00c84efc451328441eec393da694b34214ee94f841db6e03f7d6f507410a60e78165bf9d5abf96fe78bd1c",
  "0x2030246b1d2bb48b4def52e3d60040fcd2ed2ba3":
    "0x19af0b9040eefc56885bbf97907db60e628afa5ac50a0f43f96a060bfbe4904a3f55acacb100581af8040ad55f179bd9421f2c494e36998e2004c8059553a53e1c",
  "0xa163c1fdf5b4d60c5a3d4c397cba98ba387d051f":
    "0x581dc76e62ee1b22905fab41fd53690c53dd90aa592ca904ee7e9e35e8ad114729a6e90dbc9f11cd94f90f72e46f2d68941e8721410851560446e1a2d3d604911b",
  "0x3b846a646ec95f4164660a45f1483a2d1be0e8fa":
    "0x1d08653b1177ce5f91ae0d53432380c7bd87de6dafa990edaed391cbd6957a137a4a9eb15b5971e39999a832cafdefe532ffd1d8e924fff8d4b195f7bace924a1c",
  "0xc310fe78175778262efc95435511641e3de55a1a":
    "0x2940ba7fa2579c9c0379effa61ab1429f657f754f657c70cc74ed224ec3d97b75fa83f2b49491cccc37496b2c47080409dd85825e119d21636f6c7e7bf4122aa1c",
  "0x230860cf225cab168a3da26194b31af881a4c8fe":
    "0xd530615278f572841deb696430a411ad087c8a9dcddab0781a23fa500f9769ab598bfb86e183f83c34a067f84b98b3d167c2a458f188024deee1d2181e2edede1b",
  "0x85b0acce5798beaf27febe56572d7567b06c4b07":
    "0x5c721f1b60d98ac981fa760c01bdf132b29037f073480554e4098a14dc1c127a188c22f48aa479bbad93657fdf4466446f9b1a8d719544dba1185ffb135014c81b",
  "0xee35190f00ebe79857ac589ac91175862cf5ed6f":
    "0xfc4c7377a5f7f7fccf8d315adae3d3c63228fa0861829a835d9a13f54e98ee54199e02c4a6395bce2689ba89e0a06e238eeea84bbd5077a133e7389cd54c67c01b",
  "0x1be9021726e5eac98bd3079b49a8de6e60744e04":
    "0xd3ddf2dab59b9cd3da19689825d70b320993fc3a430be0e94f40682294e0b0144453d6ea1848e382544895f4b54ddf9e3b046d3946838c5e604fef1d3824b1da1b",
  "0x857e67fef21b2496369bfdba827f66795e06716c":
    "0x82c9b740233439cf1f6d0ab6719241d166c0149da5eaf5f7cdbb9aacecf6aa6407162198fe27901005976dac187b8fe73d35500501cee9d84bd8275a59b39a591c",
  "0x40a914cbb4986634a143e2881510eb5bffd6d31e":
    "0xe026a43409d088df13fd4a8e0d34c16edf88e686a684fed0f04c7bd0ed2d59ff366b50cb45e19da20528d64d99faefd14a6151838fefb09a2fc7cc9634d623891c",
  "0xf513b104d9462c2adfc57b5416e8193b6f3c6ad5":
    "0xa4d6f4b765eeb31e6bf5f5d4c051bf65685b2b2e41e99930dd09186f415403c1522aa590ea694f50e2772813c49f5da2b583ccc18095693efceb0809830d31b61b",
  "0x0bbfd9ce769142553a0cdae402e750ef04712e0c":
    "0x549584bfeb6001d502c354f8d2d85ce2f7f6c204b388c07f0ed732fc3f04359267ae2326a1ab4f0ec21bafb27f2760b6cb4f1bbd7df6c059371959e75ccdb1d81c",
  "0x2eb83642c4b6d930a523693fadb242eca8f5e4b2":
    "0xd19abb4458ec64362c1592c00c328ec11866185677a63fb1c2bcfc82065e15b27846dd5baa9e710a14cb8137f9deb8182ca866745033057af0f8e0a61b36842e1c",
  "0x7f09277b9a96d68fbf7b1ae552e4f1c5c26f2cb8":
    "0x644e947c6c2181466a02c6cb0b3024effb67351c75b2c8f5938469728cec390520147817fd5f5b2d61f4a9573835a1154a9cf9638c871a924551bd2b04fe35b51c",
  "0x83f41b75aca71d2bc9d3bec5e2f028653c57588e":
    "0xe6beb08cc023f6513feb25c5e8d46c80ec956cb1840900359d3b9ec4e8c01bd24f0eafc9b4ea0d92709a470ed614d36b20d50ae8479154b2ed1d2a51f2fd8ea51c",
  "0xdcb894dd3900e247052aad0938f125cea75fb487":
    "0x27ce391dc675affd699ef35f91b424dce9089465111538bcfa9d6c1f0ebdee9b00e92a4d4833b82335b99775c87d37b263e99fbc490563a7a5b6e945de8df4791c",
  "0x86901c89cbd79a3592498a515cf2020a52885b8d":
    "0xa76da4ff169ebebbb2b40ea1033c03bd322ad8c7c5375801391687e343b45a185622b43df686e5c048a6e87f95d782b1430158b69f186354d46ed573b00a78581b",
  "0x921082f8ec9a43689a1a026de274f71c030a0d05":
    "0x26c87a0c28c73a9054e6e76c6cead9f51964d4de85b389dfaca3dbb3de0f86d50d2ba0e9693951a8fc5f827f873e553d931c7468f8dbe82263a9902f35453fe91b",
  "0x77fa39f3adf09bde9d4f175e40d0854d21a33318":
    "0x36116d53b5c9bf25b8453832a433342a2edae562c7e11d90b966ada8ef82c3e970c82c0868b057361a39a9f1a38d1140b4c32d89b1018793d5e98cda36730f7c1b",
  "0x9f7df7d3eac4dd14d42ef761c1974ba30c09fcd4":
    "0xfa4d643d3c0ce3ae36dec07c3dcc4d3246702980e58b271d962e9a70ccf82323006ac6e08eb3d5b45910aa6f1baac3d85f0490ddc4aed5a745178b978bf7a1051c",
  "0xda0919cad986f9671f402e4999701f6caa97e9a3":
    "0x0154db63043976654a3049e69677c66f452cc834ed6dd02d0e98466cdded7b806ec02127fc5902bc5554a6ec1008c8e83fce133a3c9aaddffc494af3f85f1e851b",
  "0x7b12a62302de035d702543b6daa4377812c714ba":
    "0x0c484249291d620efeaad9fb59b57e9af9ccdcfa8e9ed771bd8574dcae80332f036bdc4f63c0f3b2cacecd6883003778a86e479b797c8df12c664b9b696afa961c",
  "0x10c6aecb7bdf6ae59d8b2d5dd8acf0189a0e0cd6":
    "0x1220589fa2fd054d83f62ecdc4cc6aedb048f42ba0e38415907a8536eda9644e652f8e39738fc81de98d971804b289e4e93e82c9d9604a0e25ccaa4e97a4c57d1b",
  "0x520e3ff45c84b87dfbe6ad5b7c1e0ddf09f6fb19":
    "0x43eb2177c18625fd2e3b511ed5a8a0582bebcc5120aad97b47d7084b7cd3d78972cfddfd38ddd36e57f230da574ef12550f876afddf9957925e2225228f77a241c",
  "0xb4df44193a60586d9229bd6307a3241d2b04ce79":
    "0x0a88dfb4404dc1ef1f0c4d50430d8b20009edbfe9135b07d4192910a7ae259e64c64d88721671c9421b7ad606342d597417edad295f9d23c267e1f537236eb5d1b",
  "0x7d2904eeea72736e54bf6222ef65a40bbedf282b":
    "0x743291d8a86f028a05e45093335f5b9c57559bdb71324d26b7b2ad69e5a8e1a823192e048053285126717fe657a7d4174bef6e086de9c7fbd8999b33eb2c85091b",
  "0x538c165070ec984deefc650541c5eccf81fbc549":
    "0xe973d1a93d93b9aa608e66247a3b6b44b197afd8facabb322cc30ce83716980267ffb4e97cff63b0624242045ea8c4485d7fe2d363b4d8b714892d8e4db5ab5d1c",
  "0x43ed490c3c2d04e5a9211446835f729616723667":
    "0xfa5c478bc4d62585d53ce97e5e045a74948e34e7f17d8384f3b4fde871eb9f79353f41a75a3b7152cdcae88a56f4a4caf740fea6028c5a56385389903bd950d11b",
  "0x3f5e3e847a79f3d1adda5f66294d89dbf755fa62":
    "0xea6622148c7e108ae5e2f7ada8c9e40adfd9ed345592261cc7599050ad38d6470cbea39bde19368e505ee2cdc2f13facc20ee436a7b77dcd8167da49a411f69e1b",
  "0xbb8de6020719569da48fdd47573e94ea7c8f95e2":
    "0x0a49ce73062c4ed7eb0f68ade43b40218d34bfcfd314807ac3430822798f62a546bffbb17161b7af0e1971ac94ab6c689f07ce2eb1bf0e5f714bfb629d2fa35b1b",
  "0xcd74fedf46c71c813e73e9201569ab7190742df7":
    "0xfe0e6222ab10047d6b30c6c98193280c95bba12724ec84a736e232c751cf0b2e4daa974ee0268b429bac490cad8ae5468fbd9b5b2440d0fca270b340416432251c",
  "0xd67a478a85eaf671176c8480943c21ebbeddb43a":
    "0x88db8e80024472cac05ae1a47fe68110014cf9ddb1ab49dfde966acb28e4c00c27952e94b538c1a12d3c61903242e644e93cc6cdb3f57539736a382c37da844a1c",
  "0x460aa467a8e9cec334141521b662a4d7ba264082":
    "0x0a3fc7492850a7d97b0329d132680a517b19ec62d4a9d948ae30ae3eb207e76715bfab4b6c4c150da732dcf44b641ddaf14dd75bda59b14b5863193ab1f0ebfb1c",
};
