import React from "react";
import { useEffect, useState } from "react";
import { ETH } from "./util/eth";
import {
  currentTravelToucansSupply,
  isPresaleActive,
  isFreeMintActive,
  // isPublicSaleActive,
  isPreListed,
  isFreeListed,
  getPreMintsPerAddress,
  getFreeMintsPerAddress,
} from "./util/interact.js";
import { MintButtons, ConnectWallet } from "./util/button";
import { connectWallet, getCurrentWalletConnected } from "./util/wallet.js";
import heroImg from "./images/TT_Logo-04-white.png";
import { presaleList } from "./util/presale";
import { freeMintList } from "./util/free";
import muteIcon from "./images/Mute_Icon.svg";
import speakerIcon from "./images/Speaker_Icon.svg";
import { Mobile } from "./util/mobile";

const TravelToucans = (props) => {
  const { muted, setMuted, isMobile, browserSupported } = props;
  const freeLocation = window.location.pathname === "/free";
  //state variables
  const [wallet, setWallet] = useState("");
  const [toucanSupply, setToucanSupply] = useState("");
  const [presaleActive, setPresaleActive] = useState(false);
  const [freeMintActive, setFreeMintActive] = useState(false);
  const [onList, setOnList] = useState(false);
  const [onFreeList, setOnFreeList] = useState(false);
  const [count, setCount] = useState(1);
  const [freeMinted, setFreeMinted] = useState(0);
  const [preMinted, setPreMinted] = useState(0);

  //called only once
  useEffect(() => {
    async function init() {
      const toucanSupply = await currentTravelToucansSupply();
      setToucanSupply(toucanSupply);

      const presaleActive = await isPresaleActive();
      setPresaleActive(presaleActive);

      const freeMintActive = await isFreeMintActive();
      setFreeMintActive(freeMintActive);

      const { address } = await getCurrentWalletConnected();
      setWallet(address);
      console.log("address", address);

      if (address.length > 0) {
        const preMints = await getPreMintsPerAddress(address);
        setPreMinted(preMints);
        const freeMints = await getFreeMintsPerAddress(address);
        setFreeMinted(freeMints);
        if (presaleActive) {
          const sig = presaleList[address.toLowerCase()];
          if (!!sig) {
            const isOnList = await isPreListed(address, sig);
            setOnList(isOnList);
          }
        }

        if (freeLocation) {
          const msg = freeMintList[address.toLowerCase()];
          if (!!msg) {
            const isOnFreeList = await isFreeListed(
              address,
              msg.amount,
              msg.sig
            );
            setOnFreeList(isOnFreeList);
          }
        }
      }
    }

    init();
  }, [freeLocation]);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setWallet(walletResponse.address);
    if (presaleActive && wallet.length > 0) {
      const sig = presaleList[wallet.toLowerCase()];
      if (!!sig) {
        const isOnList = await isPreListed(wallet, sig);
        setOnList(isOnList);
      }
    }
    if (freeMintActive && wallet > 0) {
      const msg = freeMintList[wallet.toLowerCase()];
      if (!!msg) {
        const isOnFreeList = await isFreeListed(wallet, msg.amount, msg.sig);
        setOnFreeList(isOnFreeList);
        const freeMints = await getFreeMintsPerAddress(wallet);
        setFreeMinted(freeMints);
      }
    }
  };

  const isWalletConnected = wallet.length > 0;

  let walletMessage = "Connect Wallet";
  if (isWalletConnected) {
    walletMessage = `${wallet.substring(0, 6)}...${wallet.substring(38)}`;
  }

  if (isMobile) {
    return (
      <Mobile
        browserSupported={browserSupported}
        wallet={wallet}
        count={count}
        setCount={setCount}
        presaleActive={presaleActive}
        freeLocation={freeLocation}
        freeMintActive={freeMintActive}
        freeMinted={freeMinted}
        preMinted={preMinted}
        setOnList={setOnList}
        isMobile={true}
        isWalletConnected={isWalletConnected}
        walletMessage={walletMessage}
        connectWalletPressed={connectWalletPressed}
      />
    );
  }

  const header = freeLocation ? "Free Mint" : "Presale Mint";
  return (
    <div id="container">
      <div id="imageWrapper">
        <img id="logo" src={heroImg} alt="Toucan Hero" />
      </div>
      <div className="mintContainer">
        <div className="mintInfo">
          <span className="mintHeader" style={{ fontSize: "40px" }}>
            {header}
          </span>
          {onList || onFreeList ? (
            <span className="mintHeader">
              Congratulations! You're on the List!!!
            </span>
          ) : (
            ""
          )}
          <span className="mintHeader" style={{ fontSize: "40px" }}>
            {toucanSupply} / 10001 minted
          </span>
          {freeLocation ? (
            ""
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="mintHeader">Maximum of 4 Toucans</span>

              <span className="mintHeader" style={{ marginTop: "10px" }}>
                1st Class Mint Price 0.03
                <ETH />
              </span>
              <span
                className="mintHeader"
                style={{ fontSize: "16px", marginBottom: "10px" }}
              >
                Expanded artwork, 2x voting rights, 2x ALL giveaway entries &
                much more!
              </span>
              <span className="mintHeader">
                Mint Price 0.022
                <ETH />
              </span>
            </div>
          )}

          {isWalletConnected ? (
            <MintButtons
              wallet={wallet}
              count={count}
              setCount={setCount}
              presaleActive={presaleActive}
              freeLocation={freeLocation}
              freeMintActive={freeMintActive}
              freeMinted={freeMinted}
              preMinted={preMinted}
              setOnList={setOnList}
            />
          ) : (
            <div className="mintStatus">
              <div>Please Connect Your Wallet</div>
            </div>
          )}
        </div>
      </div>
      <div className="rightContainer">
        <ConnectWallet
          connectWalletPressed={connectWalletPressed}
          walletMessage={walletMessage}
        />
        <img
          src={muted ? muteIcon : speakerIcon}
          alt="Speaker Icon"
          onClick={() => {
            setMuted(!muted);
          }}
          style={{ height: "15vh", cursor: "pointer" }}
        />
      </div>
    </div>
  );
};
export default TravelToucans;
