export const priceMap = {
  1: "0.022",
  2: "0.044",
  3: "0.066",
  4: "0.088",
  5: "0.11",
  6: "0.132",
  7: "0.154",
  8: "0.176",
  9: "0.198",
  10: "0.22",
  11: "0.242",
  12: "0.264",
  13: "0.286",
  14: "0.308",
  15: "0.33",
  16: "0.352",
  17: "0.374",
  18: "0.396",
  19: "0.418",
  20: "0.44",
  21: "0.462",
  22: "0.484",
};

export const upgradePriceMap = {
  1: "0.03",
  2: "0.06",
  3: "0.09",
  4: "0.12",
  5: "0.15",
  6: "0.18",
  7: "0.21",
  8: "0.24",
  9: "0.27",
  10: "0.30",
  11: "0.33",
  12: "0.36",
  13: "0.39",
  14: "0.42",
  15: "0.45",
  16: "0.48",
  17: "0.51",
  18: "0.54",
  19: "0.57",
  20: "0.60",
  21: "0.63",
  22: "0.66",
};

// const publicPriceMap = {
//   1: "0.04",
//   2: "0.08",
//   3: "0.12",
//   4: "0.16",
//   5: "0.20",
//   6: "0.24",
//   7: "0.28",
//   8: "0.32",
//   9: "0.36",
//   10: "0.40",
//   11: "0.44",
//   12: "0.48",
//   13: "0.52",
//   14: "0.56",
//   15: "0.60",
//   16: "0.64",
//   17: "0.68",
//   18: "0.72",
//   19: "0.76",
//   20: "0.80",
//   21: "0.84",
//   22: "0.88",
// };
//
// const publicUpgradePriceMap = {
//   1: "0.05",
//   2: "0.1",
//   3: "0.15",
//   4: "0.20",
//   5: "0.25",
//   6: "0.30",
//   7: "0.35",
//   8: "0.40",
//   9: "0.45",
//   10: "0.50",
//   11: "0.55",
//   12: "0.60",
//   13: "0.65",
//   14: "0.70",
//   15: "0.75",
//   16: "0.80",
//   17: "0.85",
//   18: "0.90",
//   19: "0.95",
//   20: "1.0",
//   21: "1.05",
//   22: "1.10",
// };
