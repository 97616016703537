export const ETH = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="ethereum"
      className="svg-inline--fa fa-ethereum fa-w-10 mr-2"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      id="ethLogo"
      width="24"
      height="24"
    >
      <path
        fill="currentColor"
        d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z"
      />
    </svg>
  );
};
