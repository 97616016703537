import TravelToucans from "./TravelToucans";
import "./App.css";
import { useState } from "react";
// import { Unsupported } from "./util/unsupported";

function App() {
  let browserSupported = false;
  if (window.ethereum) {
    browserSupported = true;
  } else {
    window.addEventListener("ethereum#initialized", handleEthereum, {
      once: true,
    });

    // If the event is not dispatched by the end of the timeout,
    // the user probably doesn't have MetaMask installed.
    setTimeout(handleEthereum, 3000); // 3 seconds
  }
  function handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      browserSupported = true;
      // Access the decentralized web!
    }
  }
  const [muted, setMuted] = useState(true);
  const isMobile = window.innerWidth <= 768;
  return (
    <div className="App">
      {isMobile ? (
        ""
      ) : (
        <video autoPlay muted={muted} loop id="myVideo">
          <source
            src="https://storage.googleapis.com/travel-toucans-media/vid.mov"
            type="video/mp4"
          />
        </video>
      )}
      <TravelToucans
        muted={muted}
        setMuted={setMuted}
        isMobile={isMobile}
        browserSupported={browserSupported}
      />
    </div>
  );
}

export default App;
