import { priceMap, upgradePriceMap } from "./price";

require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const ttAddr = process.env.REACT_APP_CONTRACT_ADDRESS;
const etherscanURL = process.env.REACT_APP_ETHERSCAN_URL;
const apiURL = process.env.REACT_APP_API_URL;

const ttABI = require("../contracts/" + ttAddr + ".json").abi;

export const ttContract = new web3.eth.Contract(ttABI, ttAddr);

export const getSignature = async (address, password) => {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + btoa(password + ":" + password));
  const response = await fetch(apiURL + "/presale/" + address, {
    method: "GET",
    headers: headers,
  });
  console.log("getTimeREs", response);
  if (response.status === 401) {
    return "unauthorized";
  }
  const r = await response.json();
  console.log("r", r);
  return r.response;
};

export const currentTravelToucansSupply = async () => {
  const s = await ttContract.methods.totalSupply().call();
  console.log("currentTravelToucansSupply", s);
  return s;
};

export const isPublicSaleActive = async () => {
  const p = await ttContract.methods.publicSaleActive().call();
  console.log("public sale active", p);
  return p;
};

export const isPresaleActive = async () => {
  const p = await ttContract.methods.presaleActive().call();
  console.log("presale sale active", p);
  return p;
};

export const isFreeMintActive = async () => {
  const p = await ttContract.methods.freeMintActive().call();
  console.log("free mint active", p);
  return p;
};

export const isPreListed = async (user, sig) => {
  const l = await ttContract.methods.isPreListed(user, sig).call();
  console.log("isPreListed", l);
  return l;
};

export const isFreeListed = async (user, amount, sig) => {
  const l = await ttContract.methods.isFreeListed(user, amount, sig).call();
  console.log("isFreeListed", l);
  return l;
};

export const getPreMintsPerAddress = async (user) => {
  const s = await ttContract.methods.preMintsPerAddress(user).call();
  console.log("preMintsPerAddress", s);
  return s;
};

export const getFreeMintsPerAddress = async (user) => {
  const s = await ttContract.methods.freeMintsPerAddress(user).call();
  console.log("freeMintsPerAddress", s);
  return s;
};

// export const purchase = async (count, isUpgraded) => {
//   if (!window.ethereum) {
//     return {
//       status: "💡 Connect your Metamask wallet to mint a Travel Toucan.",
//     };
//   }
//   const ethAddress = window.ethereum.selectedAddress;
//   const gasPrice = web3.utils.toHex(await web3.eth.getGasPrice());
//   //set up transaction parameters
//   let value = priceMap[count];
//   console.log("value", value);
//   console.log("value.toString()", value.toString());
//   let txParams = {
//     to: ttAddr, // Required except during contract publications.
//     from: ethAddress, // must match user's active address.
//     gasPrice: gasPrice,
//     value: web3.utils.toHex(web3.utils.toWei(value, "ether")),
//     data: ttContract.methods.publicMint(count, false).encodeABI(),
//   };
//   if (isUpgraded) {
//     value = upgradePriceMap[count];
//     txParams.value = web3.utils.toHex(web3.utils.toWei(value, "ether"));
//     txParams.data = ttContract.methods.publicMint(count, true).encodeABI();
//   }
//   //sign the transaction
//   try {
//     const txHash = await window.ethereum.request({
//       method: "eth_sendTransaction",
//       params: [txParams],
//     });
//     return {
//       status: (
//         <span>
//           ✅{" "}
//           <a
//             target="_blank"
//             href={`https://ropsten.etherscan.io/tx/${txHash}`}
//             rel="noreferrer"
//           >
//             View the status of your transaction on Etherscan!
//           </a>
//           <br />
//         </span>
//       ),
//     };
//   } catch (error) {
//     return { status: "😥 " + error.message };
//   }
// };

export const presale = async (count, isUpgraded, sig, setStatus) => {
  const ethAddress = window.ethereum.selectedAddress;

  let value = priceMap[count];
  if (isUpgraded) {
    value = upgradePriceMap[count];
  }
  value = web3.utils.toHex(web3.utils.toWei(value, "ether"));

  const gasAmount = await ttContract.methods
    .presale(count, isUpgraded, sig)
    .estimateGas({
      from: ethAddress,
      value: value,
    })
    .then((res) => {
      ttContract.methods
        .presale(count, isUpgraded, sig)
        .send({ from: ethAddress, value: value, gas: String(res) })
        .on("transactionHash", function (hash) {
          console.log("transactionHash", hash);
          setStatus(etherscanURL + hash);
        })
        .catch((e) => {
          console.log("\nerror\n", e);
          setStatus(e.message);
        });
    })
    .catch((e) => {
      console.log("\nCATCH error\n", e);
      if (e.message.includes("insufficient funds")) {
        setStatus("insufficient funds");
      } else {
        setStatus(e.message);
      }
    });
  console.log("estimated gas", gasAmount);
};

export const freeMint = async (count, sig, setStatus) => {
  const ethAddress = window.ethereum.selectedAddress;
  const gasAmount = await ttContract.methods.freeMint(count, sig).estimateGas({
    from: ethAddress,
  });

  ttContract.methods
    .freeMint(count, sig)
    .send({ from: ethAddress, gas: String(gasAmount) })
    .on("transactionHash", function (hash) {
      console.log("transactionHash", hash);
      setStatus(etherscanURL + hash);
    })
    .on("error", (e) => {
      console.log("\nerror\n", e);
      setStatus(e.message);
    });
};
