import { Input } from "./input";
import { freeMintList } from "./free";
import { freeMint, getSignature, presale, isPreListed } from "./interact";
import { presaleList } from "./presale";
import { priceMap, upgradePriceMap } from "./price";
import { ETH } from "./eth";
import { useState } from "react";
import React from "react";
import discordIm from "../images/discord.png";

export const ConnectWallet = (props) => {
  const { walletMessage, connectWalletPressed } = props;
  return (
    <div className="connectWallet">
      <button className="walletButton" onClick={connectWalletPressed}>
        <span>{walletMessage}</span>
      </button>
    </div>
  );
};

export const MintButtons = (props) => {
  const [status, setStatus] = useState("");
  const [remoteSignature, setRemoteSignature] = useState("");
  let {
    count,
    setCount,
    presaleActive,
    freeLocation,
    freeMintActive,
    wallet,
    setOnList,
    isMobile,
  } = props;

  const getPassword = async () => {
    const pw = window.prompt("You got it?");
    console.log("pw", pw);
    const s = await getSignature(wallet, pw);
    if (s === "unauthorized") {
      setStatus("password incorrect");
    } else {
      const isRemotePreListed = isPreListed(wallet, s);
      if (!isRemotePreListed) {
        setRemoteSignature("");
      } else {
        setRemoteSignature(s);
        setStatus("");
        setOnList(true);
      }
    }
  };
  console.log("remoteSignature", remoteSignature);

  if (status.length > 0) {
    if (status.includes("https")) {
      return (
        <div
          className="mintStatus mintButtons"
          style={{ color: "rgba(120,181,176,255)" }}
        >
          <div style={{ maxWidth: "350px" }}>
            <a
              href={status}
              target="_blank"
              style={{ color: "rgba(120,181,176,255)" }}
              rel="noreferrer"
            >
              Check your transaction on Etherscan
            </a>
          </div>
        </div>
      );
    }
    return (
      <div
        className="mintStatus mintButtons"
        style={{ color: "rgba(120,181,176,255)" }}
      >
        {status}
        {status.includes("discord") ? (
          <div>
            <a
              href="https://discord.gg/gHjVyH7Krn"
              target="_blank"
              style={{ color: "rgba(120,181,176,255)" }}
              rel="noreferrer"
            >
              <img
                src={discordIm}
                alt="Discord Logo"
                style={{ height: "80px" }}
              />
            </a>
            <div
              onClick={getPassword}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Presale Password?
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  if (freeLocation && freeMintActive) {
    return (
      <div className="mintButtons">
        <FreeMintBtn {...props} setStatus={setStatus} status={status} />
      </div>
    );
  }

  return (
    <div>
      {presaleActive ? (
        <div className="mintButtons">
          <Input count={count} setCount={setCount} />
          <MintBtn
            {...props}
            upgrade={true}
            setStatus={setStatus}
            status={status}
            remoteSignature={remoteSignature}
            isMobile={isMobile}
          />
          <MintBtn
            {...props}
            setStatus={setStatus}
            status={status}
            remoteSignature={remoteSignature}
            isMobile={isMobile}
          />
        </div>
      ) : (
        <div className="mintStatus">Presale Inactive</div>
      )}
    </div>
  );
};

const FreeMintBtn = (props) => {
  const { wallet, freeMintActive, freeMinted, setStatus, status } = props;
  const onFreeMintPressed = async () => {
    console.log("onFreeMintPressed ");
    const msg = freeMintList[wallet.toLowerCase()];
    if (!!msg && freeMintActive) {
      // const { status } =
      await freeMint(msg.amount, msg.sig, setStatus);
      console.log("Free Mint Status:", status);
      // setStatus(status.status);
    }
  };

  const msg = freeMintList[wallet.toLowerCase()];
  if (!msg || freeMinted > 0) {
    let message = "You are not eligible for a free mint";
    if (freeMinted > 0) {
      message = "Free mint already claimed";
    }
    return (
      <div className="mintStatus mintButtons">
        {message}
        <a className="mintStatus" href={window.location.origin}>
          Presale Mint
        </a>
      </div>
    );
  }

  return (
    <button className="mintBtn" onClick={() => onFreeMintPressed()}>
      <div>
        MINT<span className="mintCount">{" " + msg.amount}</span>
        FREE TOUCAN{msg.amount > 1 ? "S" : ""}
      </div>
    </button>
  );
};

const MintBtn = (props) => {
  const {
    count,
    upgrade,
    wallet,
    presaleActive,
    setStatus,
    status,
    remoteSignature,
    isMobile,
  } = props;

  const onPresalePressed = async (count, isUpgraded) => {
    console.log("onPresalePressed count:", count);
    let sig = presaleList[wallet.toLowerCase()];
    if (!sig && remoteSignature.length > 0) {
      sig = remoteSignature;
    }
    if (!!sig && presaleActive) {
      await presale(count, isUpgraded, sig, setStatus);
      console.log(status);
    } else {
      setStatus("Jump into our discord for presale.  Spots still available!");
    }
  };

  let pm = priceMap;
  if (upgrade) {
    pm = upgradePriceMap;
  }

  let style = { borderRadius: "50px" };
  if (isMobile) {
    style.boxShadow = "5px 5px 10px";
  }

  return (
    <button
      className="mintBtn"
      onClick={() => onPresalePressed(count, upgrade)}
      style={style}
    >
      <div>
        MINT
        <span className="mintCount">{" " + count}</span>
        {upgrade ? " 1st Class" : " "} for
        <span className="mintCount">{" " + pm[count]}</span>
        <ETH />
      </div>
    </button>
  );
};
