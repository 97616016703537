const maxPerTX = 4;
export const Input = (props) => {
  const { count, setCount } = props;

  const onSubtract = () => {
    if (count - 1 <= 1) {
      setCount(1);
    } else {
      setCount(count - 1);
    }
  };

  const onAdd = () => {
    if (count + 1 >= maxPerTX) {
      setCount(4);
    } else {
      setCount(count + 1);
    }
  };

  return (
    <div id="inputContainer">
      <div className="incrementContainer">
        <div className="increment" onClick={onSubtract}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            fill="#78b5b0"
            width="24px"
            height="24px"
          >
            <path d="M 5 13 A 2.0002 2.0002 0 1 0 5 17 L 25 17 A 2.0002 2.0002 0 1 0 25 13 L 5 13 z" />
          </svg>
        </div>
      </div>
      <div className={"input"}>
        <div className={"inputText"}>{count}</div>
      </div>
      <div className="incrementContainer">
        <div className="increment" onClick={onAdd}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            fill="#78b5b0"
            width="24px"
            height="24px"
          >
            <path d="M 14.970703 2.9726562 A 2.0002 2.0002 0 0 0 13 5 L 13 13 L 5 13 A 2.0002 2.0002 0 1 0 5 17 L 13 17 L 13 25 A 2.0002 2.0002 0 1 0 17 25 L 17 17 L 25 17 A 2.0002 2.0002 0 1 0 25 13 L 17 13 L 17 5 A 2.0002 2.0002 0 0 0 14.970703 2.9726562 z" />
          </svg>
        </div>
      </div>
    </div>
  );
};
