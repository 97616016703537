import { ETH } from "./eth";
import React from "react";
import heroImgPink from "../images/TT_Logo-04.png";
import mmLogo from "../images/metamask-fox-wordmark-horizontal.svg";
import { MintButtons } from "./button";

export const Mobile = (props) => {
  const {
    connectWalletPressed,
    walletMessage,
    isWalletConnected,
    wallet,
    count,
    setCount,
    presaleActive,
    freeLocation,
    freeMintActive,
    freeMinted,
    preMinted,
    setOnList,
    browserSupported,
  } = props;

  if (!browserSupported) {
    return (
      <div
        id="mobileContainer"
        style={{
          backgroundColor: "white",
        }}
      >
        <div>
          <img
            id="logo"
            src={heroImgPink}
            alt="Toucan Hero"
            style={{ height: "30vh" }}
          />
        </div>
        <div
          style={{
            borderRadius: "24px",
            boxShadow: "10px 10px grey",
            margin: "0px 15px 0px 15px",
            backgroundColor: "#ff4e00",
            backgroundImage: "linear-gradient(315deg, #ff4e00 0%, #ec9f05 74%)",
          }}
        >
          <a href="https://metamask.app.link/dapp/mint.traveltoucans.com">
            <img src={mmLogo} style={{ height: "8vh" }} alt={"Metamask link"} />
          </a>
        </div>
        <div style={{ height: "20vh", fontSize: "1.2rem" }}>
          <div>Welcome to the world of Travel Toucans.</div>
          <div>Before you can mint</div>
          <div>you will need to connect your wallet</div>
          <div>using your metamask app.</div>
        </div>
      </div>
    );
  }

  return (
    <div id="mobileContainer">
      <div>
        <img id="logo" src={heroImgPink} alt="Toucan Hero" />
      </div>
      {!browserSupported ? (
        <a href="https://metamask.app.link/dapp/mint.traveltoucans.com">
          Metamask
        </a>
      ) : (
        ""
      )}
      <div className="mobileConnectWallet">
        <button className="mobileWalletButton" onClick={connectWalletPressed}>
          <span>{walletMessage}</span>
        </button>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className="mintHeader">Maximum of 4 Toucans</span>
        <span className="mintHeader" style={{ marginTop: "10px" }}>
          1st Class Mint Price 0.03
          <ETH />
        </span>
        <span
          className="mintHeader"
          style={{ fontSize: "16px", marginBottom: "10px" }}
        >
          Expanded artwork, 2x voting rights, 2x ALL giveaway entries & much
          more!
        </span>
        <span className="mintHeader">
          Mint Price 0.022
          <ETH />
        </span>
      </div>
      {isWalletConnected ? (
        <MintButtons
          wallet={wallet}
          count={count}
          setCount={setCount}
          presaleActive={presaleActive}
          freeLocation={freeLocation}
          freeMintActive={freeMintActive}
          freeMinted={freeMinted}
          preMinted={preMinted}
          setOnList={setOnList}
          isMobile={true}
        />
      ) : (
        <div className="mintStatus">
          <div>Please Connect</div>
          <div>Your Wallet</div>
        </div>
      )}
    </div>
  );
};
